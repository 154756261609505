import { Icon, SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  IconButton, Skeleton,
  Text, useBoolean,
  useClipboard,
  useOutsideClick
} from '@chakra-ui/react'
import { useInfo } from 'services/useInfo'
import { itemType } from 'constants/itemType'
import { HiDocumentText } from 'react-icons/hi'
import { MdTextFields } from 'react-icons/md'
import { FaCheckCircle, FaCopy } from 'react-icons/fa'
import { useMemo, useRef, useState } from 'react'
import { AutoResizeTextarea } from 'components/AutoResizeTextarea'
import { useUpdateString } from 'services/useUpdateString'
import { useAppsContext } from '../../../../components/AppsContext'

const Cell = ({ data, lang, onRefresh, ...props }) => {
  const { app } = useAppsContext()
  const { updateString } = useUpdateString()

  const ref = useRef()
  const cellRef = useRef()

  const _value = useMemo(() => {
    if (data.type === itemType.string) {
      return data.value[lang]
    }
    return data.urls[lang] ? `https://${window.location.hostname}${data.urls[lang]}` : ''
  }, [data, lang])

  const [dirty, setDirty] = useState(_value)
  const [value, setValue] = useState(_value)
  const [isEdit, setIsEdit] = useBoolean(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setIsEdit.on()
  }

  const handleUpdateValue = async () => {
    const _item = {
      key: data?.key,
      appVersionNumber: data?.appVersionNumber,
      comment: data?.comment,
      deleted: false,
      value: {
        ...data?.value,
        [lang]: value
      }
    }
    const dataRequest = {
      appId: app?.id,
      strings: [_item]
    }
    if (data.type !== itemType.string) {
      return
    }
    if (dirty !== value) {
      setDirty(value)
      setIsLoading(true)
      await updateString(dataRequest)
      await onRefresh(_item)
    }
    setIsEdit.off()
  }

  useOutsideClick({
    ref: ref,
    handler: () => setIsEdit.off()
  })

  return (
    <Box
      ref={cellRef}
      position="relative"
      height="100%"
      fontFamily="Menlo, monospace"
      fontSize="0.88rem"
      fontWeight="500"
      onClick={handleClick}
      cursor="pointer"
      {...props}
    >
      <Flex
        userSelect="none"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        alignItems="center"
        height="100%"
        color={value ? 'purple.300' : 'whiteAlpha.400'}
        transition="0.1s"
        opacity={isEdit ? 0 : 1}
      >
        {isLoading ? (
          <Skeleton height="10px" width="200px" borderRadius="full"/>
        ) : (
          (_value !== '' && _value !== undefined) ? _value : 'Пусто'
        )}
      </Flex>
      {isEdit && (
        <AutoResizeTextarea
          isReadOnly={data.type !== itemType.string}
          ref={ref}
          autoFocus
          backgroundColor="gray.700"
          position="absolute"
          width="100%"
          top="14px"
          left="-17px"
          color="blue.300"
          placeholder="Пусто"
          opacity={isEdit ? 1 : 0}
          pointerEvents={isEdit ? 'auto' : 'none'}
          fontSize="0.88rem"
          height="100%"
          boxShadow="none"
          zIndex={100}
          minRows={1}
          maxRows={8}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={handleUpdateValue}
          _focus={{
            boxShadow: 'none'
          }}
          _readOnly={{
            boxShadow: 'none'
          }}
        />
      )}
    </Box>
  )
}

export const Item = ({ data, index, onSettingClick, onRefresh }) => {
  const { dataInfo } = useInfo()
  const { hasCopied, onCopy } = useClipboard(data[index].key)

  const handleCopyClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onCopy()
  }

  const handleSettingClick = () => {
    onSettingClick(data[index])
  }

  return (
    <Grid
      role="group"
      px="26px"
      height="100%"
      templateColumns={`40px minmax(200px, 1fr) repeat(${dataInfo?.languages?.length}, minmax(40px, 1fr)) 200px`}
      gap="20px"
      alignItems="center"
      _hover={{
        backgroundColor: 'whiteAlpha.100'
      }}
    >
      <Center
        boxSize="38px"
        backgroundColor="teal.300"
        borderRadius="full"
      >
        <Icon
          as={data[index].type === itemType.string ? MdTextFields : HiDocumentText}
          fontSize="1.3rem"
          color="gray.700"
        />
      </Center>
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        height="100%"
      >
        <HStack spacing="10px" height="100%">
          <Text>{data[index].key}</Text>
          <IconButton
            isRound
            colorScheme="whiteAlpha"
            aria-label="Left"
            icon={hasCopied ? <FaCheckCircle color="white" fontSize="0.7rem"/> :
              <FaCopy color="white" fontSize="0.7rem"/>}
            size="xs"
            opacity={0}
            transition="0.3s"
            _groupHover={{
              opacity: 1
            }}
            onClick={handleCopyClick}
          />
        </HStack>
      </Box>
      {dataInfo?.languages?.map(lang => (
        <Cell
          data={data[index]}
          lang={lang}
          onRefresh={onRefresh}
        />
      ))}
      <HStack spacing="14px" justifyContent="flex-end">
        <Center>
          <IconButton
            isRound
            colorScheme="whiteAlpha"
            aria-label="Left"
            icon={<SettingsIcon color="white"/>}
            size="sm"
            opacity={0}
            transition="0.3s"
            _groupHover={{
              opacity: 1
            }}
            onClick={handleSettingClick}
          />
        </Center>
        <Center
          width="fit-content"
          px="12px"
          height="20px"
          fontSize="0.9rem"
          backgroundColor="whiteAlpha.200"
          fontWeight="700"
          transition="0.3s"
          borderRadius="full"
        >
          {data[index].appVersionNumber}
        </Center>
      </HStack>
    </Grid>
  )
}
