import React, { useState } from 'react'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { Field, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { InputForm } from 'components/InputForm'
import { TextAreaForm } from 'components/TextAreaForm'
import { Box, Button, IconButton, Radio, RadioGroup, Stack, Text, useBoolean, useToast } from '@chakra-ui/react'
import { FormLabel } from 'components/FormLabel'
import { CheckboxForm } from 'components/CheckboxForm'
import { useUpdateString } from 'services/useUpdateString'
import { useInfo } from 'services/useInfo'
import { useAppsContext } from '../../../../../components/AppsContext'
import * as Yup from 'yup';
import { EditableForm } from 'components/EditableForm'
import { MdModeEditOutline } from 'react-icons/md'
import { NotAllowedIcon } from '@chakra-ui/icons'

const formSchema = Yup.object().shape({
  key: Yup.string().required('Заполните поле'),
  appVersionNumber: Yup.string().required('Заполните поле'),
})

export const StringForm = (
  { data, activeVersionNumber, setActiveVersionNumber, onRefresh, appVersionNumber }
) => {
  const toast = useToast()
  const isNew = isEmpty(data)
  const [isRename, setIsRename] = useBoolean(isNew)
  const { updateString, isLoading } = useUpdateString()
  const { dataInfo } = useInfo()
  const { app } = useAppsContext()

  const handleForm = async (values) => {
    try {
      const _appVersionNumber = (activeVersionNumber === 'key' || values.deleted) ? data?.appVersionNumber : appVersionNumber
      if (!isNew && isRename && !values.deleted) {
        await updateString({
          appId: app?.id,
          strings: [{
            ...values,
            appVersionNumber: _appVersionNumber,
            deleted: true,
            key: data?.key
          }]
        })
        await updateString({
          appId: app?.id,
          strings: [{
            ...values,
            appVersionNumber: _appVersionNumber,
            deleted: false
          }]
        })
        toast({
          description: 'Данные обновлены',
          status: 'success'
        })
        onRefresh()
        return
      }
      const dataRequest = {
        appId: app?.id,
        strings: [{
          ...values,
          ...!isNew && { appVersionNumber: _appVersionNumber }
        }]
      }
      await updateString(dataRequest)
      toast({
        description: 'Данные обновлены',
        status: 'success'
      })
      onRefresh()
    } catch (e) {}
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        key: data?.key ?? '',
        value: data.value,
        appVersionNumber: appVersionNumber,
        deleted: data?.deleted ?? false,
        comment: data?.comment ?? ''
      }}
      onSubmit={handleForm}
    >
      {(props) => {
        const isDelete = props.values.deleted
        return (
          <Form onSubmit={props.handleSubmit}>
            <BlockSection>
              <Stack spacing="6px">
                <Field
                  isHideError
                  autoFocus={isNew}
                  name="key"
                  label="Ключ"
                  component={InputForm}
                  isReadOnly={!isRename}
                  isDisabled={isLoading || isDelete}
                  {...!isNew && !props?.values?.deleted && {
                    rightElement: (
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="whiteAlpha"
                        aria-label="Left"
                        icon={isRename ? <NotAllowedIcon color="white"/> : <MdModeEditOutline color="white"/>}
                        size="xs"
                        onClick={() => setIsRename.toggle()}
                      />
                    )
                  }}
                />
                {!isNew && isRename && !props?.values?.deleted && (
                  <Text
                    fontSize="0.72rem"
                    color="orange.200"
                  >Ключ со старым именем будет удален и создан новый в выбранной версии</Text>
                )}
              </Stack>
              <Field
                name="comment"
                placeholder="Комментарий"
                component={EditableForm}
                isDisabled={isLoading}
                fontSize="0.84rem"
              />
              {!isEmpty(data) && data?.appVersionNumber !== appVersionNumber && (
                <RadioGroup
                  value={props.values.deleted ? 'key' : activeVersionNumber}
                  onChange={setActiveVersionNumber}
                  isDisabled={isLoading || isDelete}
                >
                  <FormLabel>Версия</FormLabel>
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="teal" value="selected">
                      {appVersionNumber}
                    </Radio>
                    <Radio colorScheme="red" value="key">
                      {data?.appVersionNumber}
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
              {dataInfo?.languages?.map(i => (
                <Field
                  name={`value.${i}`}
                  label={i}
                  component={TextAreaForm}
                  isDisabled={isLoading || isDelete}
                  color="purple.300"
                  fontFamily="Menlo, monospace"
                  fontSize="0.88rem"
                  minH="180px"
                />
              ))}
              {!isEmpty(data) && (
                <Field name="deleted" component={CheckboxForm} label="Удалить" />
              )}
            </BlockSection>
            <Box pt="30px" pb="20px">
              <Button
                width="100%"
                type="submit"
                colorScheme={isDelete ? 'red' : 'teal'}
                isLoading={isLoading}
              >
                {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : isRename ? 'Переименовать и сохранить' : 'Обновить'}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
