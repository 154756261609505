import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchStrings = (appId) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    request,
    error,
  } = useQuery((data) => api.admin.fetchStrings(data, appId))
  const [list, setList] = useState([])

  const clearStrings = () => {
    setList([])
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.errorText,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (response?.list) {
      setList(response?.list)
    }
  }, [response])

  return {
    fetchStrings: request,
    clearStrings,
    setStrings: setList,
    isLoadingFetchStrings: isLoading,
    dataStrings: list,
  }
}
