import { Box, Center, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'
import { MdTextFields } from 'react-icons/md';
import { HiDocumentText } from 'react-icons/hi';
import { itemType } from '../../../../../constants/itemType';
import { Icon } from '@chakra-ui/icons'

const icons = {
  strings: MdTextFields,
  files: HiDocumentText
}

export const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Center
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        //backgroundColor="teal.300"
        borderRadius="full"
        _checked={{
          bg: 'teal.300',
          color: 'white',
          borderColor: 'transparent',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        boxSize="40px"
      >
        <Icon
          as={icons[props.children]}
          fontSize="1.3rem"
          color={input?.checked ? 'gray.700' : 'white'}
          _checked={{
            color: 'gray.700',
          }}
        />
      </Center>
    </Box>
  )
}

export const RadioFilter = ({ onChange, value }) => {
  const options = ['strings', 'files']

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'typeFiles',
    defaultValue: value,
    onChange,
  })

  const group = getRootProps()

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        )
      })}
    </HStack>
  )
}
